body{
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  background-color: #fafafa;
  min-height: 100vh;
}

*{
  margin: 0;
  padding: 0;
}